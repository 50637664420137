import axios from 'axios';
import idAuthHeader from '../id-header';

const API_URL = process.env.REACT_APP_API_URL + "api/download-tracker/";

class DownloadTrackerService {
  async exportCSV(startDate, endDate) {
    return axios.get(API_URL + 'export-csv', { params: { startDate: startDate , endDate: endDate } , headers: await idAuthHeader(), responseType: 'blob' });
  }
  
  async uploadCSV(fileData) {
    return axios.post(API_URL + 'upload-csv', fileData, { headers: await idAuthHeader() , 'Content-Type': 'multipart/form-data'});
  }

  async importCSV(fileData) {
    return axios.post(API_URL + 'import-csv', fileData, { headers: await idAuthHeader() , 'Content-Type': 'multipart/form-data'});
  }

  async importUpdateCSV(fileData) {
    return axios.post(API_URL + 'import-update-csv', fileData, { headers: await idAuthHeader() , 'Content-Type': 'multipart/form-data'});
  }

  async getGrids(dailyCount) {
    return axios.get(API_URL + 'grids', { params: { dailyCount: dailyCount || 0 }, headers: await idAuthHeader() });
  }

  async getSixthGrid(filters) {
    return axios.post(API_URL + 'sixth-grid', filters, {headers: await idAuthHeader() });
  }

  async updateDownloadStatusAsync(downloadTrackerupdateModel) {
    return axios.patch(API_URL + 'download-status', downloadTrackerupdateModel, { headers: await idAuthHeader() });
  }

  async updateCompletedDateAsync(downloadTrackerupdateModel) {
    return axios.patch(API_URL + 'completed-date', downloadTrackerupdateModel, { headers: await idAuthHeader() });
  }

  async updateDownloadDateAsync(downloadTrackerupdateModel) {
    return axios.patch(API_URL + 'download-date', downloadTrackerupdateModel, { headers: await idAuthHeader() });
  }

  async updateCompletedStatusAsync(downloadTrackerupdateModel) {
    return axios.patch(API_URL + 'completed-status', downloadTrackerupdateModel, { headers: await idAuthHeader() });
  }

  async updateCompletionStatsAsync(downloadPageCountUpdateModel) {
    return axios.patch(API_URL + 'completion-stats', downloadPageCountUpdateModel, { headers: await idAuthHeader() });
  }

  async updatePageCorrectionsAsync(downloadCorrectionUpdateModel) {
    return axios.patch(API_URL + 'correction-count', downloadCorrectionUpdateModel, { headers: await idAuthHeader() });
  }

  async getReports(startDate, endDate) {
    return axios.get(API_URL + 'reports', { params: { startDate: startDate , endDate: endDate } , headers: await idAuthHeader() });
  }
}

export default new DownloadTrackerService();