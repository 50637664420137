import axios from 'axios';
import idAuthHeader from '../id-header';

const API_URL = process.env.REACT_APP_API_URL + "api/pdf-merge/";

class DownloadTrackerService {
    async getLogs(startDate, endDate) {
      return axios.get(API_URL + 'instance-logs', { params: { startDate: startDate , endDate: endDate } , headers: await idAuthHeader() });
    }

    async getCompletedLogs(startDate, endDate) {
      return axios.get(API_URL + 'completed-instance-logs', { params: { startDate: startDate , endDate: endDate } , headers: await idAuthHeader() });
    }

    async getDetails(startDate, endDate) {
      return axios.get(API_URL + 'instance-details', { params: { startDate: startDate , endDate: endDate } , headers: await idAuthHeader() });
    }
  }
  
  export default new DownloadTrackerService();