import React, { useState, useContext } from "react";
import { Box, Button, Grid, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import downloadTrackerService from "../api/services/download-tracker-service";
import { showErrorToast, showSuccessToast } from "../helpers/utils";
import { pca } from "../msalConfig";
import { PermissionsContext } from "../permission-context";
import { format } from "date-fns";
import unauth from "../images/unauth.png";
import UpdateIcon from '@mui/icons-material/Update';

const AdminDevOPS = () => {
  const today = new Date();
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(today.getDate() - 6);
  const user =
    pca && pca.getActiveAccount() ? pca.getActiveAccount().username : "";
  const { loading, error, permissions, hasPermission } =
    useContext(PermissionsContext);
  const [startDate, setStartDate] = useState(oneWeekAgo);
  const [endDate, setEndDate] = useState(today);
  const [file, setFile] = useState(null);

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    const fileName = selectedFile.name;
    const fileExtension = fileName.split(".").pop().toLowerCase();
    if (fileExtension !== "csv") {
      showErrorToast("Only CSV files are allowed.");
      return;
    }

    const reader = new FileReader();
    reader.readAsText(selectedFile);
    reader.onload = async function (e) {
      const csv = e.target.result;
      const rows = csv.split("\n");
      if (rows.length > 0) {
        const header = rows[0].trim().split(",");
        if (header.includes("Id")) {
          showErrorToast("File Contains Key column");
          return;
        }
        const expectedColumns = [
          "Applicant Name",
          "Physician Name",
          "Date of Service",
          "Files Received",
          "Page Count",
          "Duplicate Pages",
          "Erroneous Pages",
          "Blank Pages",
          "Date Downloaded (PST)",
          "Transmittal Date",
          "Vendor ETA",
          "Turnaround Time",
          "Within CME TAT",
          "Comments",
        ]; // Define your expected column names here
        const missingColumns = expectedColumns.filter(
          (column) => !header.includes(column)
        );
        if (missingColumns.length > 0) {
          showErrorToast(
            `File cannot be uploaded because the following columns are missing: ${missingColumns.join(
              ", "
            )}`
          );
        } else {
          setFile(selectedFile);
          await uploadFileToServer(selectedFile, "MissingRecords");
        }
      }
    };
  };

  const handleDatesFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    const fileName = selectedFile.name;
    const fileExtension = fileName.split(".").pop().toLowerCase();
    if (fileExtension !== "csv") {
      showErrorToast("Only CSV files are allowed.");
      return;
    }

    const reader = new FileReader();
    reader.readAsText(selectedFile);
    reader.onload = async function (e) {
      const csv = e.target.result;
      const rows = csv.split("\n");
      if (rows.length > 0) {
        const header = rows[0].trim().split(",");
        const expectedColumns = [
          "Id",
          "ApplicantName",
          "PhysicianName",
          "DateOfService",
          "FilesReceived",
          "PageCount",
          "DuplicatePages",
          "ErroneousPages",
          "BlankPages",
          "DateDownloaded (PST)",
          "TransmittalDate (PST)",
          "VendorETA",
          "TurnaroundTime",
          "WithinCMETAT",
          "Comments",
        ]; // Define your expected column names here
        const missingColumns = expectedColumns.filter(
          (column) => !header.includes(column)
        );
        if (missingColumns.length > 0) {
          showErrorToast(
            `File cannot be uploaded because the following columns are missing: ${missingColumns.join(
              ", "
            )}`
          );
        } else {
          setFile(selectedFile);
          await uploadFileToServer(selectedFile, "Date");
        }
      }
    };
  };

  const uploadFileToServer = async (csvData, uploader) => {
    const formData = new FormData();
    formData.append("file", csvData);
    formData.append("userId", user);
    console.log(formData);
    try {
      if(uploader=="Date"){
        await downloadTrackerService.importUpdateCSV(formData);
      }
      else{
        await downloadTrackerService.importCSV(formData);
      }
      showSuccessToast("File uploaded successfully!");
    } catch (error) {
      if (error.response) {
        console.error("Upload failed with status code", error.response.status);
        showErrorToast("Upload failed");
      } else {
        console.error("Upload failed with unknown error", error.message);
        showErrorToast("Upload failed");
      }
    }
  };

  const exportToCsv = async () => {
    try {
      const start = format(startDate, "yyyy-MM-dd");
      const end = format(endDate, "yyyy-MM-dd");
      const response = await downloadTrackerService.exportCSV(start, end);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "downloadtracker.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting CSV:", error);
    }
  };

  return (
    <>
      {!loading && !error && hasPermission("Admin") && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box p={4}>
            <Grid container spacing={4} direction="column">
              <Grid item>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    Import Missing Records
                  </Typography>
                  <Tooltip title="This Function is to import missing files, DO NOT USE function to update existing records.">
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    className="file-upload-form"
                    style={{ marginLeft: "2px", padding: "10px 0 20px 0" }}
                  >
                    <input
                      accept=".csv"
                      id="file-upload"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <label htmlFor="file-upload">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                        type="submit"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Import Missing Records
                      </Button>
                    </label>
                  </form>
                  </Tooltip>
                </Paper>
              </Grid>

              <Grid item>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    Update Dates
                  </Typography>
                  <Tooltip title="This Function is to update dates for the files, DO NOT USE function to import record. Make sure to use the CSV from export and that  the ID's match.">
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    className="file-upload-form"
                    style={{ marginLeft: "2px", padding: "10px 0 20px 0" }}
                  >
                    <input
                      accept=".csv"
                      id="file-upload-dates"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleDatesFileChange}
                    />
                    <label htmlFor="file-upload-dates">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        startIcon={<UpdateIcon />}
                        type="submit"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Update Dates
                      </Button>
                    </label>
                  </form>
                  </Tooltip>
                </Paper>
              </Grid>

              <Grid item>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    Export Records
                  </Typography>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item>
                      <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid item>
                      <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<DownloadIcon />}
                        onClick={exportToCsv}
                      >
                        Export Records
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </LocalizationProvider>
      )}
      {!loading && !error && !hasPermission("Admin") && (
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <img
            src={unauth}
            alt="UnAuthorized"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </Box>
      )}
    </>
  );
};

export default AdminDevOPS;
