import { pca, tokenRequest } from "../msalConfig";

export default async function authHeader() {
  const tokenResponse = await pca.acquireTokenSilent(tokenRequest);
  const accessToken = tokenResponse.accessToken;
  if (accessToken) {
    return { Authorization: "Bearer " + accessToken };
  } else {
    return {};
  }
}
