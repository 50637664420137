import { pca, tokenRequest } from "../msalConfig";

export default async function idAuthHeader() {
  const tokenResponse = await pca.acquireTokenSilent(tokenRequest);
  const idToken = tokenResponse.idToken;
  if (idToken) {
    return { Authorization: "Bearer " + idToken };
  } else {
    return {};
  }
}
