import React, { useState } from 'react';
import { InputNumber } from 'antd';
import { showErrorToast } from '../helpers/utils';

const EditableNumericCell = ({ value, onChange, originalValue, allowNegative=false }) => {
  const [inputValue, setInputValue] = useState(value);
  const [isValid, setIsValid] = useState(true);

  const handleBlur = () => {
    let parsedValue = parseInt(inputValue, 10);
    if (isNaN(parsedValue) || (!allowNegative && parsedValue < 0)) {
      parsedValue = originalValue; 
      setIsValid(false); 
      console.error("Invalid input:", inputValue); 
      showErrorToast("Invalid input: Please enter a valid integer."); 
    } else {
      setIsValid(true); 
    }
    setInputValue(parsedValue);
    onChange(parsedValue);
  };

  const handleKeyPress = (event) => {
    const { key } = event;
    if (!/[-\d]/.test(key)) {
      event.preventDefault();
    }
  };

  return (
    <InputNumber
      min={allowNegative ? -Infinity : 0}
      value={inputValue}
      onChange={setInputValue}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
      style={{ width: '100%', borderColor: isValid ? '' : 'red' }} 
    />
  );
};

export default EditableNumericCell;