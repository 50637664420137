import * as React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Grid,
  TextField,
  Container,
  Button,
  Box,
  Typography,
  Paper,
  Collapse,
} from "@mui/material";
import pdfMergeService from "../api/services/pdf-merge-service";
import { showErrorToast } from "../helpers/utils";
import { format, subDays } from "date-fns";
import { PermissionsContext } from "../permission-context";
import unauth from "../images/unauth.png";
import { styled } from "@mui/system";
import {
  Table,
  Tooltip,
  Select,
  Pagination,
  Input,
  Modal,
  Checkbox,
} from "antd";
import dayjs from 'dayjs';
import utc from 'dayjs-plugin-utc';

const formatDate = (date) => format(date, "yyyy-MM-dd");
dayjs.extend(utc);

const StatsPage = () => {
  const { loading, error, permissions, hasPermission } =
    React.useContext(PermissionsContext);
  const today = new Date();
  const [startDate, setStartDate] = React.useState(today);
  const [endDate, setEndDate] = React.useState(today);
  const [chartData, setChartData] = React.useState({
    xAxis: [],
    series: { dataCounts: [], completedCounts: [] },
  });
  const [tableData, setTableData] = React.useState([]);
  const [expandedRowKey, setExpandedRowKey] = React.useState(null);

  const StyledTable = styled(Table)`
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: "DM Sans", sans-serif;
    max-height: 600px;
    overflow: auto;

    /* Header styles */
    .ant-table-thead > tr > th {
      background: #f0f0f0;
      font-weight: bold;
      overflow: hidden;
    }

    // Body styles
    .ant-table-tbody > tr > td {
      overflow: hidden;
    }

  `;

  // Function to fetch data from API
  const fetchData = async (startDate, endDate) => {
    try {
      const start = format(startDate, 'yyyy-MM-dd');
      const end = format(endDate, 'yyyy-MM-dd');
      const response = await pdfMergeService.getLogs(start, end);
      let data = response.data;

      const completedDataResponse = await pdfMergeService.getCompletedLogs(
        start,
        end
      );
      let completedData = completedDataResponse.data;

      // Helper function to extract dates in YYYY-MM-DD format
      const extractDate = (dateStr) =>
        new Date(dateStr).toISOString().split("T")[0];

      // Extract unique dates
      const allDates = new Set([
        ...data.map((point) => extractDate(point.createdAt)),
        ...completedData.map((point) => extractDate(point.createdAt)),
      ]);

      // Initialize counts
      const counts = {};
      allDates.forEach((date) => {
        counts[date] = { dataCount: 0, completedCount: 0 }; // Default to 0
      });

      // Populate counts
      data.forEach((point) => {
        const date = extractDate(point.createdAt);
        counts[date].dataCount = point.count || 0; // Use 0 if point.count is null or undefined
      });

      completedData.forEach((point) => {
        const date = extractDate(point.createdAt);
        counts[date].completedCount = point.count || 0; // Use 0 if point.count is null or undefined
      });

      // Prepare data for chart
      const xAxisData = Array.from(allDates)
        .sort()
        .map((date) => new Date(date));
      const seriesData = xAxisData.map((date) => {
        const dateStr = extractDate(date);
        return {
          date,
          dataCount: counts[dateStr].dataCount || 0, // Ensure 0 if undefined
          completedCount: counts[dateStr].completedCount || 0, // Ensure 0 if undefined
        };
      });

      // Set chart data
      setChartData({
        xAxis: xAxisData,
        series: {
          dataCounts: seriesData.map((point) => point.dataCount),
          completedCounts: seriesData.map((point) => point.completedCount),
        },
      });

      const tableResponse = await pdfMergeService.getDetails(
        start,
        end
      );
      
      setTableData(tableResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      showErrorToast("Error fetching data:", error);
      setChartData({ xAxis: [], series: [] }); // Clear chart data on error
    }
  };

  // const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  //   borderRadius: 12,
  //   overflow: "auto",
  //   boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  //   maxWidth: "100%",
  //   fontFamily: "DM Sans",
  //   maxHeight: "500px",
  // }));

  // const StyledTableHead = styled(TableHead)(({ theme }) => ({
  //   background: "#f0f0f0",
  // }));

  const handleExpand = (rowKey) => {
    setExpandedRowKey(expandedRowKey === rowKey ? null : rowKey); // Toggle the expanded row
  };

  // Initial fetch when component mounts
  React.useEffect(() => {
    fetchData(startDate, endDate);
  }, []);

  // Update data when dates change
  React.useEffect(() => {
    fetchData(startDate, endDate);
  }, [startDate, endDate]);

  const columns = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Host Machine",
      dataIndex: "hostName",
      key: "hostName",
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
    },
  ];

  const expandedRowRender = (row) => {
    return (
      <div style={{ margin: 16 }}>
        <Typography>
          Showing Details for User: {row.user} on Machine: {row.hostName}
        </Typography>
        <Table
          columns={[
            { title: "User", dataIndex: "user", key: "user" },
            {
              title: "Host Machine",
              dataIndex: "hostMachine",
              key: "hostMachine",
            },
            {
              title: "Summary File Name",
              dataIndex: "summaryFileName",
              key: "summaryFileName",
            },
            {
              title: "Source File Name",
              dataIndex: "sourceFileName",
              key: "sourceFileName",
            },
            { title: "Version", dataIndex: "version", key: "version" },
            {
              title: "Timestamp",
              dataIndex: "timeStamp",
              key: "timeStamp",
              render: (text) =>
                text
                  ? new Date(text + "Z").toLocaleString("en-US", {
                      timeZone: "America/Los_Angeles",
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })
                  : "",
            },
          ]}
          dataSource={row.details}
          pagination={false}
          rowKey="id"
          scroll={{ y: "200px", x: "fit-content" }}
        />
      </div>
    );
  };

  return (
    <>
      {!loading && !error && hasPermission("Admin") && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box
            style={{
              padding: "20px 0 20px 0",
              width: "95%",
              marginLeft: "2.5%",
            }}
          >
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item>
                <Typography
                  variant="h4"
                  gutterBottom
                  fontWeight="700"
                  lineHeight={"31.25px"}
                  fontSize={"24px"}
                >
                  PDFMerge Application Usage Details
                </Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item>
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                  <Grid item>
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Paper
            elevation={3}
            style={{ padding: "10px", width: "95%", marginLeft: "2.5%" }}
          >
            {chartData.xAxis.length > 0 ? (
              <LineChart
                xAxis={[
                  {
                    label: "Date",
                    data: chartData.xAxis,
                    tickInterval: chartData.xAxis,
                    scaleType: "time",
                    valueFormatter: (date) => {
                      const formattedDate = dayjs(date).utc().format("MMM D");
                      return formattedDate;
                    },
                  },
                ]}
                series={[
                  {
                    label: "Distinct Usage",
                    data: chartData.series.dataCounts,
                  },
                  {
                    label: "Distinct Completed Jobs",
                    data: chartData.series.completedCounts,
                  },
                ]}
                height={200}
              />
            ) : (
              <Typography variant="body1" align="center">
                No data available for the selected date range.
              </Typography>
            )}
          </Paper>
          <Paper
            elevation={3}
            style={{
              width: "95%",
              marginLeft: "2.5%",
              marginTop: "2%",
            }}
          >
            <StyledTable
              sticky 
              dataSource={tableData}
              columns={columns}
              expandable={{
                expandedRowRender: (row) => expandedRowRender(row),
                rowExpandable: (row) => true,
                expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
                onExpand: (expanded, row) =>
                  handleExpand(row.user + row.hostName), // Handle row expansion
              }}
              rowKey={(row) => row.user + row.hostName} // Unique key for each row
              pagination={false} // Disable pagination if not needed
            />
          </Paper>
        </LocalizationProvider>
      )}
      {!loading && !error && !hasPermission("Admin") && (
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <img
            src={unauth}
            alt="UnAuthorized"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </Box>
      )}
    </>
  );
};

export default StatsPage;
