import React from "react";
import { Routes, Route, useNavigate, RouterProvider } from "react-router-dom";
import router from "./Router";
import Home from "./pages/home";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
    <ToastContainer
position="top-right"
autoClose={2000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss={false}
draggable
pauseOnHover={false}
theme="colored"
/>
    <RouterProvider router={router} />
    </>
  );
}

export default App;
