import axios from 'axios';
import authHeader from '../auth-header';
import idAuthHeader from '../id-header';

const API_URL = process.env.REACT_APP_API_URL + "api/user-management/";

class UserService {
    async getRoles(userBaseModel) {
        return axios.post(API_URL + 'roles', userBaseModel, { headers: await authHeader() });
      }

    async getUsers() {
        return axios.get(API_URL + 'users', { headers: await idAuthHeader() });
      }

    async updateUser(userModel) {
        return axios.patch(API_URL+ 'user/' + userModel.id,  userModel, { headers: await idAuthHeader() });
      }
}

export default new UserService();