import * as React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  FormControl,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Chip,
} from "@mui/material";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import userService from "../api/services/user-service";
import { showErrorToast, showSuccessToast } from "../helpers/utils";
import UserModel from "../models/user-model";
import { PermissionsContext } from "../permission-context";
import unauth from "../images/unauth.png";

const UserManagement = () => {
  const { loading, error, permissions, hasPermission } =
    React.useContext(PermissionsContext);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [users, setUsers] = React.useState([]); // Placeholder user data
  const [userDetails, setUserDetails] = React.useState({
    name: "",
    email: "",
    isActive: false,
    permissions: [],
  });

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    userService
      .getUsers()
      .then((response) => {
        const sortedUsers = response.data.sort((a, b) => b.id - a.id); // Sort by ID in descending order
        setUsers(sortedUsers);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        showErrorToast("Error fetching users:", error);
        // Handle error as needed (show message to user, retry, etc.)
      });
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    // Placeholder user details
    setUserDetails({
      name: user.name,
      email: user.email,
      isActive: user.isActive,
      permissions: user.roles || [], // Ensure roles array exists and is empty if null
    });
  };

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;

    if (type === "checkbox") {
      setUserDetails({ ...userDetails, [name]: checked });
    } else if (type === "select-multiple") {
      const selectedOptions = Array.from(
        event.target.selectedOptions,
        (option) => option.value
      );
      setUserDetails({ ...userDetails, [name]: selectedOptions });
    } else {
      setUserDetails({ ...userDetails, [name]: value });
    }
  };

  async function handleSave() {
    // Create UserModel instance based on userDetails
    const userModel = new UserModel(
      selectedUser.id,
      userDetails.name,
      userDetails.email,
      userDetails.permissions,
      userDetails.isActive
    );

    try {
      await userService.updateUser(userModel);
      showSuccessToast("Updated successfully");
      const updatedUsers = users.map((user) =>
        user.id === selectedUser.id ? userModel : user
      );
      setUsers(updatedUsers);
    } catch (error) {
      showErrorToast("Update Failed");
    }

    // Use the userModel object as needed (e.g., pass it to an API, store in state, etc.)
    console.log("Saving userModel:", userModel);
  }

  const handleCancel = () => {
    // Handle cancel logic, reset userDetails to initial state
    setSelectedUser(null);
    setUserDetails({
      name: "",
      email: "",
      isActive: false,
      permissions: [],
    });
  };

  return (
    <>
      {!loading && !error && hasPermission("Admin") && (
        <Box sx={{ p: 2 }}>
          <Typography
                  gutterBottom
                  fontWeight="700"
                  lineHeight={"31.25px"}
                  fontSize={"24px"}
                >
            User Management
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Paper
              sx={{ width: "30%", height: "80vh", overflow: "auto", mr: 2 }}
            >
              <List>
                {users.map((user, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleUserClick(user)}
                    selected={selectedUser === user}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center">
                        <span style={{ marginRight: "8px" }}>{user.name}</span>
                        {!user.isActive && (
                          <Chip
                            variant="outlined"
                            label="Disabled"
                            style={{
                              backgroundColor: "rgba(119, 119, 119, 0.2)",
                              color: "#777777",
                              marginRight: "4px",
                            }}
                            size="medium"
                          />
                        )}
                      </Box>
                      <Box display="flex" alignItems="center">
                        {user.roles && user.roles.includes("Admin") && (
                          <Chip
                            variant="outlined"
                            label="Admin"
                            style={{
                              backgroundColor: "rgba(0, 190, 30, 0.2)",
                              color: "#00BE1E",
                              marginRight: "4px",
                            }}
                            size="medium"
                          />
                        )}
                        {user.roles && user.roles.includes("User") && (
                          <Chip
                            variant="outlined"
                            label="User"
                            style={{
                              backgroundColor: "rgba(255, 122, 0, 0.2)",
                              color: "#FF7A00",
                              marginRight: "4px",
                            }}
                            size="medium"
                          />
                        )}
                      </Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Paper>
            {selectedUser && (
              <Paper
                sx={{ width: "70%", height: "80vh", overflow: "auto", p: 2 }}
              >
                <TextField
                  label="Name"
                  value={userDetails.name}
                  name="name"
                  InputProps={{ readOnly: true }}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Email"
                  value={userDetails.email}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={userDetails.isActive}
                      onChange={(event) =>
                        setUserDetails({
                          ...userDetails,
                          isActive: event.target.checked,
                        })
                      }
                      name="isLoginEnabled"
                    />
                  }
                  label="Is Login Enabled"
                  sx={{ mb: 2 }}
                />
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="permissions-label">Permissions</InputLabel>
                  <Select
                    labelId="permissions-label"
                    id="permissions"
                    multiple
                    value={userDetails.permissions}
                    label="Permissions"
                    name="permissions"
                    onChange={handleInputChange}
                  >
                    <MenuItem value={"Admin"}>Admin</MenuItem>
                    <MenuItem value={"User"}>User</MenuItem>
                  </Select>
                </FormControl>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mr: 1 }}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                  <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Box>
              </Paper>
            )}
          </Box>
        </Box>
      )}
      {!loading && !error && !hasPermission("Admin") && (
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <img
            src={unauth}
            alt="UnAuthorized"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </Box>
      )}
    </>
  );
};

export default UserManagement;
