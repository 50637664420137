import axios from 'axios';
import idAuthHeader from '../id-header';

const API_URL = process.env.REACT_APP_API_URL + "api/upload-details/";

class UploadDetailsService {
  async getGrids() {
    return axios.get(API_URL + 'history', { headers: await idAuthHeader() });
  }
}

export default new UploadDetailsService();