import React, { useState, useEffect, useRef } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { loginRequest, pca, tokenRequest } from '../msalConfig';
import userService from '../api/services/user-service';
import bgimg from '../images/login-splash.png'; 
import iconImg from '../images/icon.png';
import UserBaseModel from '../models/user-base-model';
import transdyneLogo from '../images/bannerlogo.png'
import "../App.css";

export const Login = () => {
  const [token, setToken] = useState(null);
  const [interactionInProgress, setInteractionInProgress] = useState(false);
  const navigate = useNavigate();
  const firstRender = useRef(true);

  useEffect(() => {
    const getToken = async () => {
      try {
        const account = await pca.acquireTokenSilent(tokenRequest);
        setToken(account.accessToken);
        navigate('/home');
        navigate(0);
      } catch (error) {
        setToken(null);
      }
    };

    getToken();
  });

  const handleSignIn = async () => {
    await pca.handleRedirectPromise();
    const loginResponse = await pca.loginPopup(loginRequest);
    if (loginResponse.account) {
      pca.setActiveAccount(loginResponse.account);
    }
    navigate('/home');
    navigate(0);
  };

  const handleSignOut = async () => {
    if (!interactionInProgress) {
      setInteractionInProgress(true);
      pca.logoutPopup();
      setToken(null);
      setInteractionInProgress(false);
    }
  };

  return (<>
      {!token &&(
    <div
      className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-400 to-purple-500"
      style={{ backgroundImage: `url(${bgimg})`, backgroundSize: "cover" }}
    >
      <div className="container ml-auto">
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 h-screen bg-cover bg-center md:block hidden"></div>
          <div className="md:w-1/2 bg-white p-8 shadow-lg flex flex-col items-center justify-center relative">
            <img src={transdyneLogo} alt="Icon" className="absolute top-20 w-50% h-auto" />
            <div className="text-center mb-10">
              <h3 className="text-3xl font-semibold mb-4">Welcome back!</h3>
            </div>
            <div className={"buttonContainer"}>
              <input
                className={"inputButton"}
                type="button"
                onClick={token ? handleSignOut : handleSignIn}
                value={token ? "Log out" : "Log in"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>)
  }</>
  );
};
