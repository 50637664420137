import React from 'react';
import { createBrowserRouter} from 'react-router-dom';
import {Login} from './pages/login'
import ProtectedRoute from './ProtectedRoute';
import Home from './pages/home';
import UserManagement from './pages/manage-users';
import { pca, tokenRequest } from './msalConfig';
import StatsPage from './pages/pdf-stats';
import CSVUploadHistory from './pages/csv-upload-history';
import { PermissionsProvider} from "./permission-context";
import ReportPage from './pages/reports';
import AdminDevOPS from './pages/admin-devops';

// Function to get the access token from cookies
const getAccessToken = async () => {
  const account = await pca.getActiveAccount();
  if (!account) {
    return false; 
  }
  else{
  return true;
}
}

// Function to check if the user is authenticated
const isAuthenticated = async() => {
  return !!await getAccessToken();
}

// Create the router configuration
const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Login />,
      index: true
    },
    {
      element: (
          <PermissionsProvider>
            <ProtectedRoute isAuthenticated={isAuthenticated} />
          </PermissionsProvider>
      ),
      children: [
        {
          path: '/home',
          element: <Home />
        },
        {
          path: '/manage-users',
          element: <UserManagement />
        },
        {
          path: '/pdf-merge-stats',
          element: <StatsPage />
        },
        {
          path: '/upload-history',
          element: <CSVUploadHistory />
        },
        {
          path: '/reports',
          element: <ReportPage />
        },
        {
          path: '/admin-devops',
          element: <AdminDevOPS />
        }
      ]
    },
    {
      path: '*',
      element: <p>404 Error - Nothing here...</p>
    }
  ]
);

export default router;