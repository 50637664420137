import React, { useEffect, useState, useContext } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TableContainer,
  Paper,
  TablePagination,
  TableSortLabel,
  Tooltip,
  Box,
  Skeleton,
  Grid
} from "@mui/material";
import { styled } from "@mui/system"; 
import {
  convertIntToString,
  showErrorToast,
} from "../helpers/utils";
import { PermissionsContext } from "../permission-context";
import uploadDetailsService from "../api/services/upload-details-service";
import unauth from "../images/unauth.png";
import UploadStatus from "../constants/enums/upload-status";

// Styled components for custom styling
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: 12,
  overflowX: "auto", // Enable horizontal scrolling
  boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  maxWidth: "100%", // Ensure the table container does not exceed its parent's width
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  background: "#f0f0f0",
}));

const CSVUploadHistory = () => {
  const { loading, error, permissions, hasPermission } =
    useContext(PermissionsContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("apptDate");
  const [order, setOrder] = useState("asc");
  const [data, setGrids] = useState([]);
  const [showTableSkeleton, setSkeleton] = useState(true);

  const fetchData = () => {
    uploadDetailsService
      .getGrids()
      .then((response) => {
        const sortedHistory = response.data.sort((a, b) => b.id - a.id);
        setGrids(sortedHistory); // Update state with fetched data
        setSkeleton(false);
      })
      .catch((error) => {
        console.error("Error fetching grids:", error);
        showErrorToast("Error fetching grids:", error);
        // Handle error as needed (show message to user, retry, etc.)
      });
  };

  React.useEffect(() => {
    setSkeleton(true);
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const statusColors = {
    0: '#f8d7da',    // Failed
    //1: '#d4edda',  // Success
    2: '#fff3cd'  // Partial
  };

  return (
    <>
      {!loading && !error && hasPermission("Admin") && (
        <div style={{ margin: "20px 0", overflowX: "auto", padding: "2%" }}>
          {showTableSkeleton ? (
            <Skeleton variant="rectangular" width={"100%"} height={"60vh"} />
          ) : (
            <>
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item>
              <Typography
                variant="h4"
                gutterBottom
                fontWeight="700"
                lineHeight={"31.25px"}
                fontSize={"24px"}
              >
                CME Reports
              </Typography>
            </Grid>
          </Grid>
              <StyledTableContainer component={Paper}>
                <Table>
                  <StyledTableHead>
                    <TableRow>
                      <TableCell>Uploaded By</TableCell>
                      <TableCell>
                        {/* <TableSortLabel
                          active={orderBy === "uploadedOn"}
                          direction={orderBy === "uploadedOn" ? order : "asc"}
                        > */}
                        Uploaded On
                        {/* </TableSortLabel> */}
                      </TableCell>
                      <TableCell>File Name</TableCell>
                      <TableCell>Upload Status</TableCell>
                      <TableCell>Details</TableCell>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {data && data.length > 0 ? (
                      stableSort(data, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => {
                          const backgroundColor =
                            statusColors[item.uploadStatus] || "white"; 

                          return (
                            <TableRow key={item.id} style={{ backgroundColor }}>
                              <TableCell>{item.uploadedBy}</TableCell>
                              <TableCell>
                                {item.uploadedOn
                                  ? new Date(
                                      item.uploadedOn + "Z"
                                    ).toLocaleString("en-US", {
                                      timeZone: "America/Los_Angeles",
                                      weekday: "long",
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                      hour: "numeric",
                                      minute: "numeric",
                                      hour12: true,
                                    })
                                  : ""}
                              </TableCell>
                              <TableCell>{item.fileName}</TableCell>
                              <TableCell>
                                {convertIntToString(
                                  item.uploadStatus,
                                  UploadStatus
                                )}
                              </TableCell>
                              <TableCell>{item.uploadStatusDetails}</TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No entries found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </StyledTableContainer>
              <div
                style={{
                  margin: "20px 0",
                  overflowX: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* Pagination at the right bottom */}
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, 20, data ? data.length : 0]}
                  component="div"
                  count={data ? data.length : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  style={{ alignSelf: "flex-end" }}
                />
              </div>
            </>
          )}
        </div>
      )}
      {!loading && !error && !hasPermission("Admin") && (
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <img
            src={unauth}
            alt="UnAuthorized"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </Box>
      )}
    </>
  );
};

export default CSVUploadHistory;
