class DownloadTrackerPageCountUpdateModel {
    constructor(id, hasDeposition, isCorrection, duplicatePageCount, erroneousPageCount, blankPageCount, resultDocxPageCount) {
      this.id = id;
      this.hasDeposition = hasDeposition;
      this.isCorrection = isCorrection;
      this.duplicatePageCount = duplicatePageCount;
      this.erroneousPageCount = erroneousPageCount;
      this.blankPageCount = blankPageCount;
      this.resultDocxPageCount = resultDocxPageCount;
    }
  }
  
  export default DownloadTrackerPageCountUpdateModel;