import React, { createContext, useState, useEffect } from 'react';
import UserBaseModel from './models/user-base-model';
import userService from './api/services/user-service';
import { pca } from './msalConfig';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { Navigate, Outlet } from 'react-router-dom';

export const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const hasPermission = (permission) => {
    return permissions.includes(permission);
  };

  const fetchAndSetRoles = async () => {
    const account = pca.getActiveAccount(); 
    if (account) {
      const userBaseModel = new UserBaseModel(account.name, account.username);
      try {
        const response = await userService.getRoles(userBaseModel);
        setPermissions(response.data);
      } catch (error) {
        console.error('Error fetching roles:', error);
        setError('Failed to fetch roles. Please try again later.');
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    } else {
      setLoading(false); // If no account, also set loading to false
    }
  };

  useEffect(() => {
    fetchAndSetRoles();
  }, []);

  return (
    <PermissionsContext.Provider value={{ permissions, setPermissions, hasPermission, loading, error }}>
      {children}
      {loading && <div className="loader"></div>}
      {error && (
        <Navigate to="/" replace />
      )}
    </PermissionsContext.Provider>
  );
};