import { React, useEffect, useState, useContext} from "react";
import DownloadTrackerGrid from "../components/download-tracker-grid";
import {
  Box,
  Tab,
  Tabs,
  Button,
  Typography,
  ThemeProvider,
  Skeleton,
  Tooltip,
  Grid
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import downloadTrackerService from "../api/services/download-tracker-service";
import { showErrorToast, showSuccessToast } from "../helpers/utils";
import { pca } from "../msalConfig";
import { PermissionsContext } from "../permission-context";

function Home() {
  const { loading, error, permissions, hasPermission } = useContext(PermissionsContext);
  const [value, setValue] = useState("one");
  const [grids, setGrids] = useState([]);
  const [showTableSkeleton, setSkeleton] = useState(true);
  const [file, setFile] = useState(null);
  const user =
    pca && pca.getActiveAccount() ? pca.getActiveAccount().username : "";
  const [dailyCount, setDailyCount] = useState(4500);
  const [debouncedInputValue, setDebouncedInputValue] = useState(4500);
  
  const handleCountChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      // Regex to test if input is numeric
      setDailyCount(value === "" ? "" : parseInt(value, 10));
    }
  };

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(dailyCount);
    }, 300);
    return () => clearTimeout(delayInputTimeoutId);
  }, [dailyCount, 300]);

  useEffect(() => {
    setSkeleton(true);
    setGrids(null);
    fetchData();
  }, [debouncedInputValue, 300]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    const fileName = selectedFile.name;
    const fileExtension = fileName.split(".").pop().toLowerCase();
    if (fileExtension !== "csv") {
      showErrorToast("Only CSV files are allowed.");
      return;
    }

    const reader = new FileReader();
    reader.readAsText(selectedFile);
    reader.onload = async function (e) {
      const csv = e.target.result;
      const rows = csv.split("\n");
      if (rows.length > 0) {
        const header = rows[2].trim().split(",");
        const expectedColumns = [
          "Applicant",
          "QME",
          "Appt Date",
          "Appt Type",
          "Page Count (Total)",
          "ROR Status",
          "ROR Started",
          "Priority",
          "Escalate",
        ]; // Define your expected column names here
        const missingColumns = expectedColumns.filter(
          (column) => !header.includes(column)
        );
        if (missingColumns.length > 0) {
          showErrorToast(
            `File cannot be uploaded because the following columns are missing: ${missingColumns.join(
              ", "
            )}`
          );
        } else {
          setFile(selectedFile);
          // Optionally, you can set userId here if it's provided directly from UI
          await uploadFileToServer(selectedFile);
        }
      }
    };
  };

  const uploadFileToServer = async (csvData) => {
    const formData = new FormData();
    formData.append("file", csvData);
    formData.append("userId", user);
    console.log(formData);
    try {
      await downloadTrackerService.uploadCSV(formData);
      showSuccessToast("File uploaded successfully!");
      setSkeleton(true);
      setGrids(null);
      fetchData();
    } catch (error) {
      if (error.response) {
        console.error("Upload failed with status code", error.response.status);
        showErrorToast("Upload failed");
      } else {
        console.error("Upload failed with unknown error", error.message);
        showErrorToast("Upload failed");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    downloadTrackerService
      .getGrids(dailyCount)
      .then((response) => {
        setGrids(response.data); // Update state with fetched data
        setSkeleton(false);
      })
      .catch((error) => {
        console.error("Error fetching grids:", error);
        showErrorToast("Error fetching grids:", error);
        // Handle error as needed (show message to user, retry, etc.)
      });
  };

  function MyTab(props) {
    return (
      <Tooltip title={props.tooltip}>
        <Tab {...props} wrapped={true}/>
      </Tooltip>
    );
  }

  return (
    <>
      {!loading && !error && hasPermission("User") && (<>
          <Box style={{ padding: "20px 0 20px 0", width: "95%", marginLeft: "2.5%" }}>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item>
                <Typography
                  gutterBottom
                  fontWeight="700"
                  lineHeight={"31.25px"}
                  fontSize={"24px"}
                >
                  CME Download Tracker
                </Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item className="daily-count-container">
                  <label htmlFor="daily-count-input">Daily Capacity : </label>
              <Tooltip title="Change to modify Daily Capacity">
                <input
                  id="daily-count-input"
                  type="text"
                  value={dailyCount}
                  onChange={handleCountChange}
                />
              </Tooltip>
                  </Grid>
                  <Grid item>
                  <Tooltip title="Click to upload the ICE status CSV">
              <form
                onSubmit={(e) => e.preventDefault()}
                className="file-upload-form"
              >
                <input
                  accept=".csv"
                  id="file-upload"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label htmlFor="file-upload">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    type="submit"
                  >
                    Upload
                  </Button>
                </label>
              </form>
            </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          
          <Box style={{ width: "95%", marginLeft: "2.5%" }}>
            <Box sx={{ width: "100%", padding:0 }}>
              <Tabs
                value={value}
                variant="scrollable"
                allowScrollButtonsMobile
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="primary tabs example"
              >
                <MyTab
                  value="one"
                  label="Grid 1- Supplementals, Priority and Next 3 day Jobs"
                  tooltip="Display all `Supplemental`, Priority 1/2 appointments that are either not downloaded or downloaded today, and are scheduled for today or within the next 3 days."
                />
                <MyTab
                  value="two"
                  label="Grid 2- Next 15 Days under 1000 Pages"
                  tooltip="Displays Appointments scheduled between the next 3 and 14 days with less than 1000 pages that are either not downloaded or downloaded today. Sorted by appointment date and page count. Total pages are within the daily capacity after considering Grid 1. Records from Grid 1 are excluded."
                />
                <MyTab
                  value="three"
                  label="Grid 3- Jobs with 1000 Pages or More"
                  tooltip="Displays all records with more than 1000 pages that are either not downloaded or downloaded today. Records from Grid 1 are excluded."
                />
                <MyTab
                  value="four"
                  label="Grid 4- Other Jobs"
                  tooltip="Displays all remaining records. Records from Grid 1,2 and 3 are excluded."
                />
                <MyTab
                  value="five"
                  label="Grid 5- Past Downloads in Progress"
                  tooltip="Displays all records which are downloaded till yesterday but not completed."
                />
                <MyTab
                  value="six"
                  label="Grid 6- Past Records"
                  tooltip="Displays all records which are downloaded and completed."
                />
              </Tabs>

              <Box>
                {showTableSkeleton ? (
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"680px"}
                    style={{margin:"20px 0"}}
                  />
                ) : (
                  <>
                    {value === "one" && (
                      <DownloadTrackerGrid gridName={"one"} data={grids["gridOne"]} dailyCount={dailyCount}/>
                    )}
                    {value === "two" && (
                      <DownloadTrackerGrid data={grids["gridTwo"]} dailyCount={dailyCount}/>
                    )}
                    {value === "three" && (
                      <DownloadTrackerGrid data={grids["gridThree"]} dailyCount={dailyCount}/>
                    )}
                    {value === "four" && (
                      <DownloadTrackerGrid gridName={"four"} data={grids["gridFour"]} dailyCount={dailyCount}/>
                    )}
                    {value === "five" && (
                      <DownloadTrackerGrid gridName={"five"} data={grids["gridFive"]} dailyCount={dailyCount}/>
                    )}
                    {value === "six" && (
                      <DownloadTrackerGrid gridName={"six"} data={grids["gridSix"]} dailyCount={dailyCount}/>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box></>
      )}
      {!loading && !error && !hasPermission("User") && (
        <h1>You Do Not Have Permissions To View Page, Contact Administrator</h1>
      )}
    </>
  );
}
export default Home;
