
import { ToastContainer, toast } from 'react-toastify';

export function convertIntToString(value, enumObject) {
  return Object.keys(enumObject).find((key) => enumObject[key] === value);
}

export function convertStringToInt(value, enumObject) {
  if (typeof value !== 'string') {
      throw new Error('Input value must be a string');
  }

  if (!enumObject || typeof enumObject !== 'object') {
      throw new Error('Invalid enum object');
  }

  const result = enumObject[value];
  if (result === undefined) {
      throw new Error(`Value "${value}" does not exist in the enum object.`);
  }

  return result;
}
export function showInfoToast(message) {
  toast.info(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    progress: undefined,
    theme: "dark",
  });
}

export function showErrorToast(message) {
  toast.error(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    progress: undefined,
    theme: "dark",
  });
}

export function showSuccessToast(message) {
  toast.success(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    progress: undefined,
    theme: "dark",
  });
}

export function getPageValue(type, str) {
  const parts = str.split(',');
  const pageCountPart = parts.find(part => part.includes(type));
  if (!pageCountPart) {
    return 0;
  }
  const pageCountValue = parseInt(pageCountPart.split(':')[1].trim());
  return pageCountValue;
}

export function getTimeDifference(date1, date2) {
  const isWeekend = (date) => {
    const day = date.getUTCDay();
    return day === 0 || day === 6; 
  };

  if (date1 < date2) {
    [date1, date2] = [date2, date1]; 
  }

  let timeDiff = date1.getTime() - date2.getTime();

  while (isWeekend(date1)) {
    date1.setUTCDate(date1.getUTCDate() - 1);
    timeDiff -= 24 * 60 * 60 * 1000;
  }

  while (isWeekend(date2)) {
    date2.setUTCDate(date2.getUTCDate() + 1);
    timeDiff += 24 * 60 * 60 * 1000;
  }

  const totalDays = Math.floor(timeDiff / (24 * 60 * 60 * 1000));
  const totalHours = Math.floor((timeDiff % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));

  if (totalDays === 0 && totalHours === 0) {
    return '';
  }

  if (totalHours === 24) {
    return `${totalDays + 1} days`;
  }

  if (totalDays === 0) {
    return `${totalHours} hours`;
  }
  
  return `${totalDays} days${totalHours > 0 ? ` ${totalHours} hours` : ''}`;
}


