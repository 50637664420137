import UserBaseModel from "./user-base-model";
class UserModel extends UserBaseModel{
    constructor(id, name, email, roles, isActive) {
        super(name, email);
        this.id = id;
        this.roles = roles || []; 
        this.isActive = isActive || false; 
    }
}

export default UserModel;
