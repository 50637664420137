import * as React from "react";
import {
  AppBar,
  Avatar,
  Box,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/Inbox";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import HistoryIcon from "@mui/icons-material/History";
import { useState } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest, pca, tokenRequest } from "../msalConfig";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { PeopleAlt } from "@mui/icons-material";
import { PermissionsContext } from "../permission-context";
import SummarizeIcon from '@mui/icons-material/Summarize';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

function NavBar() {
  const navigate = useNavigate();
  const activeAccount = pca.getActiveAccount();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const location = useLocation();
  const history = useNavigate();
  const [selectedNavItem, setSelectedNavItem] = React.useState("");
  const { permissions, hasPermission, setPermissions } = React.useContext(PermissionsContext);

  React.useEffect(() => {
    if (location.pathname.endsWith("/home")) {
      setSelectedNavItem("inbox");
    } else if (location.pathname.endsWith("/manage-users")) {
      setSelectedNavItem("manage-users");
    } else if (location.pathname.endsWith("/pdf-merge-stats")) {
      setSelectedNavItem("pdf-merge-stats");
    } else if (location.pathname.endsWith("/upload-history")) {
      setSelectedNavItem("upload-history");
    } else if (location.pathname.endsWith("/reports")) {
      setSelectedNavItem("reports");
    } else if (location.pathname.endsWith("/admin-devops")) {
      setSelectedNavItem("admin-devops");
    }else {
      setSelectedNavItem("");
    }
  }, [location.pathname]);

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    navigate("/");
  };

  const handleSignOut = async () => {
    try {
      const logoutRequest = {
        account: activeAccount,
        postLogoutRedirectUri: process.env.REACT_APP_LogoutRedirectURI,
      };
      pca
        .handleRedirectPromise()
        .then((tokenResponse) => {
              setPermissions([]);
              pca.logoutRedirect(logoutRequest); 
        })
        .catch((err) => {
          // Handle error
          console.error(err);
        });
    } catch (error) {
      console.error("Logout error:", error);
    }
    // handleClose();
  };

  // Function to get initials from account name
  const getInitials = () => {
    if (activeAccount && activeAccount.name) {
      const nameParts = activeAccount.name.split(" ");
      if (nameParts.length > 1) {
        return (
          nameParts[0].charAt(0) + nameParts[nameParts.length - 1].charAt(0)
        );
      } else {
        return nameParts[0].charAt(0);
      }
    }
    return "";
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerOpen}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            TransDyne
          </Typography>
          {activeAccount && (
            <div>
              <IconButton onClick={handleAvatarClick} sx={{ p: 0 }}>
                <Avatar sx={{ bgcolor: "#3f51b5", ml: 2 }}>
                  {getInitials()}
                </Avatar>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        anchor="left"
        open={openDrawer}
        onClose={handleDrawerClose}
        sx={{
          width: 240,
          flexShrink: 0,
          zIndex: (theme) => theme.zIndex.drawer,
          "& .MuiDrawer-paper": { width: 240, boxSizing: "border-box" },
        }}
      >
        <Box display="flex" justifyContent="flex-end" width="100%">
          <IconButton onClick={handleDrawerClose}>
            <ArrowBackIosNewIcon />
          </IconButton>
        </Box>
        <List>
          <ListItem
            button
            onClick={() => {
              handleDrawerClose();
              navigate("/home");
            }}
            selected={selectedNavItem === "inbox"}
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Inbox" />
          </ListItem>
          {hasPermission("Admin") && (
            <ListItem
              button
              onClick={() => {
                handleDrawerClose();
                navigate("/manage-users");
              }}
              selected={selectedNavItem === "manage-users"}
            >
              <ListItemIcon>
                <PeopleAlt />
              </ListItemIcon>
              <ListItemText primary="Manage Users" />
            </ListItem>
          )}
          {hasPermission("Admin") && (
            <ListItem
              button
              onClick={() => {
                handleDrawerClose();
                navigate("/pdf-merge-stats");
              }}
              selected={selectedNavItem === "pdf-merge-stats"}
            >
              <ListItemIcon>
                <PictureAsPdfIcon />
              </ListItemIcon>
              <ListItemText primary="PDF Merge Stats" />
            </ListItem>
          )}
          {hasPermission("Admin") && (
            <ListItem
              button
              onClick={() => {
                handleDrawerClose();
                navigate("/upload-history");
              }}
              selected={selectedNavItem === "upload-history"}
            >
              <ListItemIcon>
                <HistoryIcon />
              </ListItemIcon>
              <ListItemText primary="CSV Upload History" />
            </ListItem>
          )}
          {hasPermission("Admin") && (
            <ListItem
              button
              onClick={() => {
                handleDrawerClose();
                navigate("/reports");
              }}
              selected={selectedNavItem === "reports"}
            >
              <ListItemIcon>
                <SummarizeIcon  />
              </ListItemIcon>
              <ListItemText primary="Reports" />
            </ListItem>
          )}
          {hasPermission("Admin") && (
            <ListItem
              button
              onClick={() => {
                handleDrawerClose();
                navigate("/admin-devops");
              }}
              selected={selectedNavItem === "admin-devops"}
            >
              <ListItemIcon>
                <DeveloperModeIcon  />
              </ListItemIcon>
              <ListItemText primary="Admin DevOPS" />
            </ListItem>
          )}
        </List>
      </Drawer>
    </Box>
  );
}
export default NavBar;
