import React, { useEffect, useState, useContext } from "react";
import downloadTrackerService from "../api/services/download-tracker-service";
import { Typography, TablePagination } from "@mui/material";
import { styled } from "@mui/system";
import {
  Table,
  Tooltip,
  Select,
  Pagination,
  Input,
  Modal,
  Button,
  Checkbox,
} from "antd";
import { pca } from "../msalConfig";
import DownloadTrackerStatusUpdateModel from "../models/download-tracker-update-model";
import CMEAppointmentTypes from "../constants/enums/appointmentype";
import CME_ROR_Status from "../constants/enums/ror_status";
import DownloadStatus from "../constants/enums/download-status";
import {
  convertIntToString,
  showSuccessToast,
  showInfoToast,
  showErrorToast,
  getPageValue,
  getTimeDifference,
  convertStringToInt,
} from "../helpers/utils";
import { PermissionsContext } from "../permission-context";
import EditableNumericCell from "../components/editable-numeric-cell";
import DownloadTrackerPageCountUpdateModel from "../models/download-tracker-page-count-update-model";
import moment from "moment";
import DownloadTrackerDateUpdateModel from "../models/download-tracker-date-update-model";
import DownloadTrackerCorrectionUpdateModel from "../models/download-tracker-correction-update-model";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { addDays } from "date-fns";
import { DatePicker as AntDDatePicker } from "antd";

const { RangePicker } = AntDDatePicker;
const user =
  pca && pca.getActiveAccount() ? pca.getActiveAccount().username : "";

// Styled components for custom styling
const StyledTable = styled(Table)`
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: 'DM Sans', sans-serif;,
  maxHeight: "600px",
}));

  .ant-table-thead > tr > th {
    background: #f0f0f0;
    position: 'sticky',
    top: 0,
    zIndex:1,
    font-weight:12px,
  }

  .ant-table-tbody > tr > td {
    overflow: hidden;
    white-space: nowrap;
  }
.ant-table-fixed-left .ant-table-tbody > tr > td {
  border-right: 1px solid #d9d9d9; 
}

.ant-table-fixed-left .ant-table-thead > tr > th {
  border-right: 1px solid #d9d9d9; 
}
`;

const DownloadTrackerGrid = ({ gridName, data, dailyCount }) => {
  const { loading, error, permissions, hasPermission } =
    useContext(PermissionsContext);
  const [orderBy, setOrderBy] = useState("apptDate");
  const [order, setOrder] = useState("asc");
  const [gridData, setData] = useState(data);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [paginatedData, setPaginatedData] = useState([]);
  const [invalidInputs, setInvalidInputs] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [updateModalData, setUpdateModalData] = useState(null);
  const [showCancelled, setShowCancelled] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [shouldFetch, setShouldFetch] = React.useState(false);
  const [filters, setFilters] = useState({
    applicant: "",
    qme: "",
    appointmentDate: [dayjs().subtract(7, "day"), dayjs()],
    downloadDate: [],
    completedDate: [],
  });

  const highlightedIds = [];
  const gridTotal = data.reduce((sum, record) => {
    if (
      record.apptType === 3 ||
      record.priority === 1 ||
      record.priority === 2
    ) {
      return sum + record.pageCount;
    }
    return sum;
  }, 0);
  let remainingCount = dailyCount - gridTotal;
  const sortedData = [...data]
    .filter(
      (record) =>
        !(
          record.apptType === 3 ||
          record.priority === 1 ||
          record.priority === 2
        )
    )
    .sort((a, b) => new Date(a.apptDate) - new Date(b.apptDate));
  sortedData.forEach((record) => {
    if (remainingCount >= record.pageCount) {
      remainingCount -= record.pageCount;
      highlightedIds.push(record.id);
    } else {
      return;
    }
  });

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (shouldFetch) {
      fetchData();
      setShouldFetch(false);
    }
  }, [shouldFetch]);

  useEffect(() => {
    if (gridName === "six") {
      fetchData();
      return;
    }
  }, [gridName]);

  useEffect(() => {
    setTableLoading(true);
    const start = (current - 1) * pageSize;
    const end = start + pageSize;
    showCancelled
      ? setPaginatedData(gridData.slice(start, end))
      : setPaginatedData(
          gridData
            .filter(
              (item) => item.downloadStatus !== 2 && item.downloadStatus !== 3
            )
            .slice(start, end)
        );

    setTableLoading(false);
  }, [current, pageSize, gridData, showCancelled]);

  const fetchData = async () => {
    console.log(filters);
    setTableLoading(true);
    const formattedFilters = {
      applicant: filters.applicant,
      qme: filters.qme,
      dateRange: [
        filters.appointmentDate[0]?.format("YYYY-MM-DD"),
        filters.appointmentDate[1]?.format("YYYY-MM-DD"),
      ],
      downloadDateRange: [
        filters.downloadDate[0]
          ? dayjs(filters.downloadDate[0])?.format("YYYY-MM-DD")
          : null,
        filters.downloadDate[1]
          ? filters.downloadDate[1]?.format("YYYY-MM-DD")
          : null,
      ],
      completedDateRange: [
        filters.completedDate[0]
          ? filters.completedDate[0]?.format("YYYY-MM-DD")
          : null,
        filters.completedDate[1]
          ? filters.completedDate[1]?.format("YYYY-MM-DD")
          : null,
      ],
    };

    console.log(formattedFilters);
    try {
      const response = await downloadTrackerService.getSixthGrid(
        formattedFilters
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching grids:", error);
      showErrorToast("Error fetching grids:", error);
    } finally {
      setTableLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div style={{ color: "red" }}>{error}</div>;
  }

  const handleShowCancelledChange = (e) => {
    setShowCancelled(e.target.checked);
  };
  const handleCorrectionCellChange = async (
    record,
    key,
    dataIndex,
    value,
    originalValue
  ) => {
    if (value === originalValue) return;
    try {
      var updateModel = new DownloadTrackerCorrectionUpdateModel(
        record.id,
        dataIndex,
        value
      );
      await downloadTrackerService.updatePageCorrectionsAsync(updateModel);
      const updatedData = gridData.map((item) => {
        if (item.id === key) {
          return {
            ...item,
            [dataIndex]: value,
          };
        }
        return item;
      });
      setData(updatedData);
      showSuccessToast("Updated successfully");
    } catch (error) {
      console.error("Error updating data:", error);
      showErrorToast("Failed to update data.");
    }
  };

  const handleCellChange = async (
    record,
    key,
    dataIndex,
    value,
    originalValue
  ) => {
    if (value === originalValue) return;

    const itemIndex = gridData.findIndex((item) => item.id === key);
    if (itemIndex !== -1) {
      let parsedValue = parseInt(value, 10);
      if (isNaN(parsedValue) || parsedValue < 0) {
        parsedValue = originalValue;
        showErrorToast(
          "Invalid input: Please enter a positive integer or zero."
        );
        setInvalidInputs({ ...invalidInputs, [key]: true });
        return;
      } else {
        setInvalidInputs({ ...invalidInputs, [key]: false });
      }

      try {
        var updateModel = new DownloadTrackerPageCountUpdateModel(
          record.id,
          record.hasDeposition,
          record.isCorrection,
          dataIndex === "duplicatePageCount"
            ? value
            : record.duplicatePageCount,
          dataIndex === "erroneousPageCount"
            ? value
            : record.erroneousPageCount,
          dataIndex === "blankPageCount" ? value : record.blankPageCount,
          dataIndex === "resultDocxPageCount"
            ? value
            : record.resultDocxPageCount
        );
        await downloadTrackerService.updateCompletionStatsAsync(updateModel);
        const updatedData = gridData.map((item) => {
          if (item.id === key) {
            return {
              ...item,
              [dataIndex]: parsedValue,
            };
          }
          return item;
        });
        setData(updatedData);
        showSuccessToast("Updated successfully");
      } catch (error) {
        console.error("Error updating data:", error);
        showErrorToast("Failed to update data.");
      }
    }
  };

  function addBusinessDays(startDate, businessDaysToAdd) {
    const date = new Date(startDate);
    let daysAdded = 0;

    while (daysAdded < businessDaysToAdd) {
      date.setDate(date.getDate() + 1);
      if (date.getDay() !== 0 && date.getDay() !== 6) {
        daysAdded++;
      }
    }

    return date;
  }

  function isDatePlusBusinessDaysToday(startDate, businessDaysToAdd) {
    const targetDate = addBusinessDays(startDate, businessDaysToAdd);
    const today = new Date();
    return targetDate.toDateString() === today.toDateString();
  }

  async function updateDate(id, date, field) {
    if (date == null) return;
    const localDate = new Date(`${date} UTC-0800`);
    const utcDateString = localDate.toISOString();

    var updateModel = new DownloadTrackerDateUpdateModel(
      id,
      utcDateString,
      user
    );
    try {
      switch (field) {
        case "Download":
          console.log(updateModel);
          await downloadTrackerService.updateDownloadDateAsync(updateModel);
          break;
        case "Completed":
          await downloadTrackerService.updateCompletedDateAsync(updateModel);
          break;
        default:
          throw new Error("Invalid field specified");
      }
      const updatedData = gridData.map((item) => {
        if (item.id === id) {
          switch (field) {
            case "Download":
              item.downloadDate = date;
              break;
            case "Completed":
              item.completedDate = date;
              break;
            default:
              throw new Error("Invalid field specified");
          }
        }
        return item;
      });
      setData(updatedData);
      showSuccessToast("Updated successfully");
    } catch (error) {
      console.log(error);
      showErrorToast("Update Failed");
    }
  }

  // Function to update download status
  async function updateStatus(record, newValue, id, field) {
    const boolValue =
      newValue === null ? null : newValue === "true" || newValue === 1;

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    let yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;

    var updateModel = new DownloadTrackerStatusUpdateModel(id, boolValue, user);
    try {
      switch (field) {
        case "DownloadStatus":
          updateModel = new DownloadTrackerStatusUpdateModel(
            id,
            newValue,
            user
          );
          await downloadTrackerService.updateDownloadStatusAsync(updateModel);
          break;
        case "CompletedStatus":
          await downloadTrackerService.updateCompletedStatusAsync(updateModel);
          break;
        case "HasDeposition":
          var depositionUpdateModel = new DownloadTrackerPageCountUpdateModel(
            record.id,
            boolValue,
            record.isCorrection,
            record.duplicatePageCount,
            record.erroneousPageCount,
            record.blankPageCount,
            record.resultDocxPageCount
          );
          await downloadTrackerService.updateCompletionStatsAsync(
            depositionUpdateModel
          );
          break;
        case "IsCorrection":
          var correctionUpdateModel = new DownloadTrackerPageCountUpdateModel(
            record.id,
            record.hasDeposition,
            boolValue,
            record.duplicatePageCount,
            record.erroneousPageCount,
            record.blankPageCount,
            record.resultDocxPageCount
          );
          await downloadTrackerService.updateCompletionStatsAsync(
            correctionUpdateModel
          );
          break;
        default:
          throw new Error("Invalid field specified");
      }
      const updatedData = gridData.map((item) => {
        if (item.id === id) {
          switch (field) {
            case "DownloadStatus":
              item.downloadStatus = newValue;
              item.downloadDate = boolValue ? today : null;
              break;
            case "CompletedStatus":
              item.completedStatus = boolValue;
              item.completedDate = boolValue ? today : null;
              break;
            case "HasDeposition":
              item.hasDeposition = boolValue;
              break;
            case "IsCorrection":
              item.isCorrection = boolValue;
              break;
            default:
              throw new Error("Invalid field specified");
          }
        }
        return item;
      });
      setData(updatedData);
      showSuccessToast("Updated successfully");
    } catch (error) {
      console.log(error);
      showErrorToast("Update Failed");
    }
  }

  const isCompleteStatusEnabled = (downloadStatus) => {
    return downloadStatus === DownloadStatus.True;
  };

  const { Option } = Select;
  const columns = [
    {
      title: "Applicant",
      dataIndex: "applicant",
      fixed: "left",
      width: 150,
      onCell: (record) => ({
        className: gridName === "one" ? cellClassName(record) : "",
      }),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        if (gridName !== "six") {
          return null;
        }

        const handleReset = () => {
          handleFilterChange("applicant", "");
          setSelectedKeys([]);
          confirm();
          setShouldFetch(true); // Trigger fetch on reset
        };

        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search Applicant"
              value={filters.applicant}
              onChange={(e) => {
                handleFilterChange("applicant", e.target.value);
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              style={{ marginBottom: 8, display: "block" }}
            />
            <Button
              type="primary"
              onClick={() => {
                confirm();
                fetchData();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={handleReset} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
    },
    {
      title: "QME",
      dataIndex: "qme",
      fixed: "left",
      width: 150,
      onCell: (record) => ({
        className: gridName === "one" ? cellClassName(record) : "",
      }),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        if (gridName !== "six") {
          return null;
        }

        const handleReset = () => {
          handleFilterChange("qme", "");
          setSelectedKeys([]);
          confirm();
          setShouldFetch(true); // Trigger fetch on reset
        };

        return (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search qme"
              value={filters.qme}
              onChange={(e) => {
                handleFilterChange("qme", e.target.value);
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              style={{ marginBottom: 8, display: "block" }}
            />
            <Button
              type="primary"
              onClick={() => {
                confirm();
                fetchData();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={handleReset} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
    },
    {
      title: "Appointment Date",
      dataIndex: "apptDate",
      fixed: "left",
      render: (text, record) => (
        <Tooltip
          title={
            <>
              {`ApptType: ${convertIntToString(
                record.apptType,
                CMEAppointmentTypes
              )}`}
              <br />
              {`Downloaded: ${convertIntToString(
                record.downloadStatus,
                DownloadStatus
              )}`}
              <br />
              {`Completed: ${
                record.completedStatus.toString().charAt(0).toUpperCase() +
                record.completedStatus.toString().slice(1)
              }`}
              <br />
              {`ROR Status: ${convertIntToString(
                record.rorStatus,
                CME_ROR_Status
              )}`}
            </>
          }
        >
          <span>{text ? text.split("T")[0] : ""}</span>
        </Tooltip>
      ),
      width: 150,
      onCell: (record) => ({
        className: gridName === "one" ? cellClassName(record) : "",
      }),
      sorter: (a, b) => new Date(a.apptDate) - new Date(b.apptDate),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        if (gridName !== "six") {
          return null;
        }

        const initialKeys =
          filters.appointmentDate && filters.appointmentDate.length > 0
            ? filters.appointmentDate.map((date) => date.format("YYYY-MM-DD"))
            : [];

        if (selectedKeys.length === 0 && initialKeys.length > 0) {
          setSelectedKeys(initialKeys);
          confirm();
        }

        const handleReset = () => {
          handleFilterChange("appointmentDate", "");
          setSelectedKeys([]);
          setFilters((prev) => ({
            ...prev,
            appointmentDate: [dayjs().subtract(7, "day"), dayjs()],
          }));
          confirm();
          setShouldFetch(true);
        };

        const defaultSelectedKeys = filters.appointmentDate
          ? filters.appointmentDate.map((date) => date.format("YYYY-MM-DD"))
          : [];

        const isDateRangeValid =
          filters.appointmentDate && filters.appointmentDate.length === 2;

        return (
          <div style={{ padding: 8 }}>
            <RangePicker
              style={{ marginBottom: 8 }}
              value={filters.appointmentDate}
              onChange={(dates) => {
                const dateRange = dates
                  ? dates.map((date) => date.format("YYYY-MM-DD"))
                  : [];
                setSelectedKeys(dateRange);
                setFilters((prev) => ({
                  ...prev,
                  appointmentDate: dates,
                }));
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                confirm();
                fetchData();
              }}
              size="small"
              style={{ width: 90 }}
              disabled={!isDateRangeValid}
            >
              Filter
            </Button>
            <Button onClick={handleReset} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
    },
    {
      title: "Page Count",
      dataIndex: "pageCount",
      render: (text, record) => (
        <Tooltip title={record.pageCountTotal}>
          <span>{text}</span>
        </Tooltip>
      ),
      width: 150,
      fixed: "left",
      onCell: (record) => ({
        className: gridName === "one" ? cellClassName(record) : "",
      }),
    },
    {
      title: "Appointment Type",
      dataIndex: "apptType",
      render: (text, record) =>
        convertIntToString(record.apptType, CMEAppointmentTypes),
      width: 150,
    },
    {
      title: "ROR Status",
      dataIndex: "rorStatus",
      render: (text, record) =>
        convertIntToString(record.rorStatus, CME_ROR_Status),
      width: 150,
    },
    {
      title: "ROR Started",
      dataIndex: "rorStarted",
      render: (text) => (text ? text.split("T")[0] : ""),
      width: 150,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      width: 150,
    },
    {
      title: "Escalate",
      dataIndex: "escalate",
      render: (text) => text.toString().toUpperCase(),
      width: 150,
    },
    {
      title: "Required TAT",
      dataIndex: "requiredTAT",
      width: 150,
    },
    {
      title: "Actual TAT",
      dataIndex: "actualTAT",
      render: (text, record) => {
        if (record && record.completedDate && record.downloadDate) {
          return getTimeDifference(
            dayjs(record.completedDate).subtract(8, "hour").toDate(),
            dayjs(record.downloadDate).subtract(8, "hour").toDate()
          );
        } else {
          return "";
        }
      },
      width: 150,
    },
    {
      title: "Download Status",
      dataIndex: "downloadStatus",
      render: (text, record) => (
        <Select
          disabled={record.completedStatus}
          defaultValue={record.downloadStatus}
          onChange={(value) =>
            updateStatus(record, value, record.id, "DownloadStatus")
          }
          style={{ width: "100%" }}
        >
          <Option value={DownloadStatus.AlreadyCompleted}>
            Already Completed
          </Option>
          <Option value={DownloadStatus.Cancelled}>Cancelled</Option>
          <Option value={DownloadStatus.True}>Yes</Option>
          <Option value={DownloadStatus.False}>No</Option>
        </Select>
      ),
      width: 150,
    },
    {
      title: "Download Date",
      dataIndex: "downloadDate",
      render: (text, record) => {
        if (gridName === "five" || gridName === "six") {
          const originalDate = dayjs(record.downloadDate).subtract(8, "hour");
          let selectedDate = originalDate;
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <AntDDatePicker
                maxDate={record.completedDate ? dayjs(record.completedDate).subtract(8, "hour") : null}
                showTime={{ defaultValue: dayjs().hour(8).minute(0) }}
                defaultValue={originalDate}
                format="YYYY-MM-DD HH:mm"
                onChange={(date) => {
                  if (date) {
                    const completedDate = dayjs(record.completedDate).subtract(8, "hour");
                    if (
                      completedDate.isValid() &&
                      date.isAfter(completedDate)
                    ) {
                      alert(
                        "Selected date cannot be greater than the completed date."
                      );
                      return; 
                    }
                    selectedDate = date;
                    const newDate = date
                      ? date.format("YYYY-MM-DD HH:mm")
                      : null;
                    updateDate(record.id, newDate, "Download");
                  }
                }}
                inputReadOnly 
              />
            </LocalizationProvider>
          );
        }

        if (!text) return "";

        const utcDate = new Date(text);
        const options = {
          timeZone: "America/Los_Angeles",
        };

        const year = utcDate.toLocaleString("en-US", {
          ...options,
          year: "numeric",
        });
        const month = utcDate.toLocaleString("en-US", {
          ...options,
          month: "2-digit",
        });
        const day = utcDate.toLocaleString("en-US", {
          ...options,
          day: "2-digit",
        });

        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      },
      width: 190,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        if (gridName !== "six") {
          return null;
        }

        const handleReset = () => {
          handleFilterChange("downloadDate", "");
          setSelectedKeys([]);
          setFilters((prev) => ({
            ...prev,
            downloadDate: [],
          }));
          confirm();
          setShouldFetch(true);
        };

        const defaultSelectedKeys = filters.downloadDate
          ? filters.downloadDate.map((date) => date.format("YYYY-MM-DD"))
          : [];

        const isDateRangeValid =
          filters.downloadDate && filters.downloadDate.length === 2;

        return (
          <div style={{ padding: 8 }}>
            <RangePicker
              style={{ marginBottom: 8 }}
              onChange={(dates) => {
                const dateRange = dates
                  ? dates.map((date) => date.format("YYYY-MM-DD"))
                  : [];
                setSelectedKeys(dateRange);
                setFilters((prev) => ({
                  ...prev,
                  downloadDate: dates,
                }));
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                confirm();
                fetchData();
              }}
              size="small"
              style={{ width: 90 }}
              disabled={!isDateRangeValid}
            >
              Filter
            </Button>
            <Button onClick={handleReset} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
    },
    {
      title: "Complete Status",
      dataIndex: "completedStatus",
      render: (text, record) => (
        <Select
          defaultValue={text ? "true" : "false"}
          onChange={(value) =>
            updateStatus(record, value, record.id, "CompletedStatus")
          }
          disabled={!isCompleteStatusEnabled(record.downloadStatus)}
        >
          <Option value="true">Yes</Option>
          <Option value="false">No</Option>
        </Select>
      ),
      width: 150,
    },
    {
      title: "Completed Date",
      dataIndex: "completedDate",
      render: (text, record) => {
        if (
          isCompleteStatusEnabled(record.downloadStatus) &&
          record.completedStatus &&
          (gridName === "five" || gridName === "six")
        ) {
          const originalDate = dayjs(record.completedDate).subtract(8, "hour");
          let selectedDate = originalDate;

          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <AntDDatePicker
                minDate={
                  record.downloadDate ? dayjs(record.downloadDate).subtract(8, 'hour') : null
                }
                showTime={{ defaultValue: dayjs().hour(17).minute(0) }}
                defaultValue={originalDate}
                format="YYYY-MM-DD HH:mm"
                onChange={(date) => {
                  if (date) {
                    const downloadDate = dayjs(record.downloadDate).subtract(8, "hour");
                    if (
                      downloadDate.isValid() &&
                      date.isBefore(downloadDate)
                    ) {
                      alert(
                        "Selected date cannot be before than the download date."
                      );
                      return; 
                    }
                    selectedDate = date;
                    const newDate = date
                      ? date.format("YYYY-MM-DD HH:mm")
                      : null;
                    updateDate(record.id, newDate, "Completed");
                  }
                }}
              />
            </LocalizationProvider>
          );
        }

        if (!text) return "";

        const utcDate = new Date(text);
        const options = { timeZone: "America/Los_Angeles" };

        const formattedDate = utcDate.toLocaleString("en-US", {
          ...options,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });

        return formattedDate;
      },
      width: 190,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        if (gridName !== "six") {
          return null;
        }

        const handleReset = () => {
          handleFilterChange("completedDate", "");
          setSelectedKeys([]);
          setFilters((prev) => ({
            ...prev,
            completedDate: [],
          }));
          confirm();
          setShouldFetch(true);
        };

        const defaultSelectedKeys = filters.completedDate
          ? filters.completedDate.map((date) => date.format("YYYY-MM-DD"))
          : [];

        const isDateRangeValid =
          filters.completedDate && filters.completedDate.length === 2;

        return (
          <div style={{ padding: 8 }}>
            <RangePicker
              style={{ marginBottom: 8 }}
              onChange={(dates) => {
                const dateRange = dates
                  ? dates.map((date) => date.format("YYYY-MM-DD"))
                  : [];
                setSelectedKeys(dateRange);
                setFilters((prev) => ({
                  ...prev,
                  completedDate: dates,
                }));
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                confirm();
                fetchData();
              }}
              size="small"
              style={{ width: 90 }}
              disabled={!isDateRangeValid}
            >
              Filter
            </Button>
            <Button onClick={handleReset} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
    },
    {
      title: "AA Page Count",
      dataIndex: "aaPageCount",
      width: 150,
    },
    {
      title: "DA Page Count",
      dataIndex: "daPageCount",
      width: 150,
    },
    {
      title: "ADJ Page Count",
      dataIndex: "adjPageCount",
      width: 150,
    },
    {
      title: "MISC Page Count",
      dataIndex: "miscPageCount",
      width: 150,
    },
    {
      title: "AA Correction",
      dataIndex: "aaCorrection",
      width: 150,
      editable: true,
      hidden:
        gridName === "one" ||
        gridName === "two" ||
        gridName === "three" ||
        gridName === "four",
      render: (text, record) => (
        <EditableNumericCell
          value={text}
          allowNegative={true}
          onChange={(value) =>
            handleCorrectionCellChange(
              record,
              record.id,
              "aaCorrection",
              value,
              text
            )
          }
        />
      ),
    },
    {
      title: "DA Correction",
      dataIndex: "daCorrection",
      width: 150,
      editable: true,
      hidden:
        gridName === "one" ||
        gridName === "two" ||
        gridName === "three" ||
        gridName === "four",
      render: (text, record) => (
        <EditableNumericCell
          value={text}
          allowNegative={true}
          onChange={(value) =>
            handleCorrectionCellChange(
              record,
              record.id,
              "daCorrection",
              value,
              text
            )
          }
        />
      ),
    },
    {
      title: "ADJ Correction",
      dataIndex: "adjCorrection",
      width: 150,
      editable: true,
      hidden:
        gridName === "one" ||
        gridName === "two" ||
        gridName === "three" ||
        gridName === "four",
      render: (text, record) => (
        <EditableNumericCell
          value={text}
          allowNegative={true}
          onChange={(value) =>
            handleCorrectionCellChange(
              record,
              record.id,
              "adjCorrection",
              value,
              text
            )
          }
        />
      ),
    },
    {
      title: "MISC Correction",
      dataIndex: "miscCorrection",
      width: 150,
      editable: true,
      hidden:
        gridName === "one" ||
        gridName === "two" ||
        gridName === "three" ||
        gridName === "four",
      render: (text, record) => (
        <EditableNumericCell
          value={text}
          allowNegative={true}
          onChange={(value) =>
            handleCorrectionCellChange(
              record,
              record.id,
              "miscCorrection",
              value,
              text
            )
          }
        />
      ),
    },
    {
      title: "Has Deposition",
      dataIndex: "hasDeposition",
      render: (text, record) => {
        let defaultValue = text !== null ? text.toString() : null; // Convert text to string for comparison

        return (
          <Select
            value={defaultValue}
            onChange={(value) =>
              updateStatus(
                record,
                value === "true" ? "true" : value === "false" ? "false" : null,
                record.id,
                "HasDeposition"
              )
            }
          >
            <Option value={null}>Select</Option>
            <Option value="true">Yes</Option>
            <Option value="false">No</Option>
          </Select>
        );
      },
      width: 150,
    },
    {
      title: "Is Correction",
      dataIndex: "isCorrection",
      render: (text, record) => (
        <Select
          defaultValue={text ? "true" : "false"}
          onChange={(value) =>
            updateStatus(record, value, record.id, "IsCorrection")
          }
        >
          <Option value="true">Yes</Option>
          <Option value="false">No</Option>
        </Select>
      ),
      width: 150,
    },
    {
      title: "Duplicate Pages",
      dataIndex: "duplicatePageCount",
      width: 150,
      editable: true,
      render: (text, record) => (
        <EditableNumericCell
          value={text}
          onChange={(value) =>
            handleCellChange(
              record,
              record.id,
              "duplicatePageCount",
              value,
              text
            )
          }
        />
      ),
    },
    {
      title: "Erroneous Pages",
      dataIndex: "erroneousPageCount",
      width: 150,
      editable: true,
      render: (text, record) => (
        <EditableNumericCell
          value={text}
          onChange={(value) =>
            handleCellChange(
              record,
              record.id,
              "erroneousPageCount",
              value,
              text
            )
          }
        />
      ),
    },
    {
      title: "Blank Pages",
      dataIndex: "blankPageCount",
      width: 150,
      editable: true,
      render: (text, record) => (
        <EditableNumericCell
          value={text}
          onChange={(value) =>
            handleCellChange(record, record.id, "blankPageCount", value, text)
          }
        />
      ),
    },
    {
      title: "Result Docx Page Count",
      dataIndex: "resultDocxPageCount",
      width: 150,
      editable: true,
      render: (text, record) => (
        <EditableNumericCell
          value={text}
          onChange={(value) =>
            handleCellChange(
              record,
              record.id,
              "resultDocxPageCount",
              value,
              text
            )
          }
        />
      ),
    },
    {
      title: "Record Created At",
      dataIndex: "recordCreatedDate",
      width: 150,
      render: (text) =>
        text
          ? new Date(text + "Z").toLocaleString("en-US", {
              timeZone: "America/Los_Angeles",
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })
          : "",
    },
  ];

  const handleTableChange = (page, size) => {
    setCurrent(page);
    setPageSize(size);
  };

  const handlePageSizeChange = (value) => {
    if (value === "all") {
      setPageSize(gridData.length); // Set page size to total records
      setCurrent(1); // Reset to the first page
    } else {
      setPageSize(Number(value)); // Set selected page size
      setCurrent(1); // Reset to the first page
    }
  };

  async function handleSave() {
    try {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0
      let yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;

      await downloadTrackerService.updateCompletedStatusAsync(updateModalData);

      const completeUpdateModel = new DownloadTrackerPageCountUpdateModel(
        modalData.id,
        modalData.hasDeposition,
        modalData.isCorrection,
        modalData.duplicatePageCount,
        modalData.erroneousPageCount,
        modalData.blankPageCount,
        modalData.resultDocxPageCount
      );
      await downloadTrackerService.updateCompletionStatsAsync(
        completeUpdateModel
      );

      const updatedData = gridData.map((item) => {
        if (item.id === modalData.id) {
          return {
            ...item,
            completedStatus: updateModalData.boolValue,
            completedDate: updateModalData.boolValue ? today : null,
            hasDeposition: modalData.hasDeposition,
            isCorrection: modalData.isCorrection,
            duplicatePageCount: modalData.duplicatePageCount,
            erroneousPageCount: modalData.erroneousPageCount,
            blankPageCount: modalData.blankPageCount,
            resultDocxPageCount: modalData.resultDocxPageCount,
          };
        }
        return item;
      });

      setData(updatedData);
      showSuccessToast("Updated successfully");

      setShowPopup(false);
      setUpdateModalData(null);
      setModalData(null);
    } catch (error) {
      console.error("Error updating data:", error);
      showErrorToast("Failed to update data.");
    }
  }

  const handleCancel = () => {
    const updatedData = gridData.map((item) => {
      if (item.id === modalData.id) {
        return {
          ...item,
          completedStatus: false,
          completedDate: null,
        };
      }
      return item;
    });
    setData(updatedData);
    setShowPopup(false);
    setUpdateModalData(null);
    setModalData(null);
  };

  const handleNumericChange = (field, value) => {
    const parsedValue = parseInt(value, 10);
    if (isNaN(parsedValue) || parsedValue < 0) {
      return;
    }
    setModalData({ ...modalData, [field]: parsedValue });
  };

  const rowClassName = (record) => {
    const recordDate = new Date(record.apptDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    let className = "";

    if (gridName === "one") {
      if (
        record.apptType === 3 ||
        record.priority === 1 ||
        record.priority === 2
      ) {
        if (recordDate <= today) {
          className += "highlight-active highlight-font-red";
        } else {
          className = "highlight-active";
        }
        return className;
      }
      if (highlightedIds.includes(record.id)) {
        if (recordDate <= today) {
          className += "highlight-cancelled highlight-font-red";
        } else {
          className = "highlight-cancelled";
        }
        return className;
      }
    }
    if (gridName === "five") {
      if (
        isDatePlusBusinessDaysToday(record.downloadDate, record.requiredTAT)
      ) {
        className = "highlight-font-orange";
      }
    }
    if (recordDate <= today && gridName !== "six") {
      className = "highlight-font-red";
    }
    return className;
  };

  const cellClassName = (record, index, indent, column) => {
    if (
      record.apptType === 3 ||
      record.priority === 1 ||
      record.priority === 2
    ) {
      return "highlight-active";
    }
    if (highlightedIds.includes(record.id)) {
      return "highlight-cancelled";
    }
    return "";
  };

  const title = () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {gridName === "one" && (
          <>
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: "#d4edda",
                marginRight: "8px",
              }}
            ></div>
            <span>Supplemental and Priority 1/2 &emsp;</span>
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: "#fff3cd",
                margin: "0 8px",
              }}
            ></div>
            <span>
              Daily Capacity minus records which are Supplemental or Priority
              1/2
            </span>
          </>
        )}
      </div>
      <div>
        <Checkbox checked={showCancelled} onChange={handleShowCancelledChange}>
          Show Cancelled/Already Completed
        </Checkbox>
      </div>
    </div>
  );

  return (
    <div style={{ margin: "20px 0", overflowX: "auto", fontFamily: "DM Sans" }}>
      <StyledTable
        columns={columns}
        dataSource={paginatedData}
        scroll={{ y: "600px", x: "max-content" }}
        pagination={false}
        rowKey="id"
        style={{ tableLayout: "fixed" }}
        title={title}
        rowClassName={rowClassName}
        loading={tableLoading}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 16,
        }}
      >
        <div>
          <Typography
            variant="body2"
            style={{ alignSelf: "flex-start", marginLeft: 10 }}
          >
            Total Pages:{" "}
            {gridData.reduce((total, item) => total + item.pageCount, 0)}
            <br />
            Total Supplementals and Priority 1/2:{" "}
            {gridData
              .filter(
                (item) =>
                  item.apptType === 3 ||
                  item.priority === 1 ||
                  item.priority === 2
              )
              .reduce((total, item) => total + item.pageCount, 0)}
            <br />
            Total Pages Downloaded:{" "}
            {gridData
              .filter((item) => item.downloadStatus === DownloadStatus.True)
              .reduce((total, item) => total + item.pageCount, 0)}
            <br />
            Total Pages Completed:{" "}
            {gridData
              .filter((item) => item.completedStatus === true)
              .reduce((total, item) => total + item.pageCount, 0)}
            <br />
            Total Jobs: {gridData ? gridData.length : 0}
            <br />
            <span style={{ color: "red" }}>
              All Totals Excluding Cancelled Jobs:{" "}
              {gridData
                ? gridData.filter(
                    (item) => item.downloadStatus !== DownloadStatus.Cancelled
                  ).length
                : 0}
            </span>
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Pagination
            total={gridData.length}
            current={current}
            pageSize={pageSize}
            onChange={handleTableChange}
            showSizeChanger={false} // Disable the default size changer
          />
          <Select
            defaultValue={pageSize.toString()}
            style={{ width: 120, marginRight: 16 }}
            onChange={handlePageSizeChange}
          >
            <Option value="10">10</Option>
            <Option value="20">20</Option>
            <Option value="50">50</Option>
            <Option value="all">Show All</Option>
          </Select>
        </div>
      </div>
      <Modal
        title="Completed Status Details"
        open={showPopup}
        onCancel={handleCancel}
        footer={[
          <Button key="save" type="primary" onClick={handleSave}>
            Save
          </Button>,
          <Button key="close" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        {modalData && (
          <div>
            <div>
              <span>Applicant: {modalData.applicant}</span>
            </div>
            <div>
              <span>QME: {modalData.qme}</span>
            </div>
            {/* Add other fields as needed */}
            <div>
              <span>Has Deposition:</span>
              <Select
                value={
                  modalData.hasDeposition === null
                    ? null
                    : modalData.hasDeposition
                    ? "true"
                    : "false"
                }
                onChange={(value) =>
                  setModalData({
                    ...modalData,
                    hasDeposition:
                      value === "true"
                        ? true
                        : value === "false"
                        ? false
                        : null,
                  })
                }
              >
                <Select.Option value={null}>Select</Select.Option>
                <Select.Option value="true">Yes</Select.Option>
                <Select.Option value="false">No</Select.Option>
              </Select>
            </div>
            <div>
              <span>Is Correction:</span>
              <Select
                defaultValue={modalData.isCorrection ? "true" : "false"}
                onChange={(value) =>
                  setModalData({ ...modalData, isCorrection: value === "true" })
                }
              >
                <Select.Option value="true">Yes</Select.Option>
                <Select.Option value="false">No</Select.Option>
              </Select>
            </div>
            <div>
              <span>Duplicate PageCount:</span>
              <Input
                type="number"
                value={modalData.duplicatePageCount}
                onChange={(e) =>
                  handleNumericChange("duplicatePageCount", e.target.value)
                }
              />
            </div>
            <div>
              <span>Blank PageCount:</span>
              <Input
                type="number"
                value={modalData.blankPageCount}
                onChange={(e) =>
                  handleNumericChange("blankPageCount", e.target.value)
                }
              />
            </div>
            <div>
              <span>Erroneous PageCount:</span>
              <Input
                type="number"
                value={modalData.erroneousPageCount}
                onChange={(e) =>
                  handleNumericChange("erroneousPageCount", e.target.value)
                }
              />
            </div>
            <div>
              <span>Result Docx PageCount:</span>
              <Input
                type="number"
                value={modalData.resultDocxPageCount}
                onChange={(e) =>
                  handleNumericChange("resultDocxPageCount", e.target.value)
                }
              />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default DownloadTrackerGrid;
