import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_ClientId,
        authority: "https://login.microsoftonline.com/" + process.env.REACT_APP_TenantId,
        redirectUri: process.env.REACT_APP_RedirectURI
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
};

const loginRequest = {
    scopes: ["openid", "offline_access"]
};

const tokenRequest = {
    scopes: [process.env.REACT_APP_SCOPE],
    forceRefresh: true,
    refreshTokenExpirationOffsetSeconds: 7200 
};

const graphConfig = {
    graphUsersEndpoint: "https://graph.microsoft.com/v1.0/users"
};

const pca = new PublicClientApplication(msalConfig);
async function initializeMsal() {
    await pca.initialize();
}
initializeMsal();

export {
    msalConfig,
    loginRequest,
    tokenRequest,
    graphConfig,
    pca
};