import React, { useState, useEffect } from "react";
import { Grid, TextField, Box, Typography, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Table, Tooltip } from "antd";
import downloadTrackerService from "../api/services/download-tracker-service";
import { styled } from "@mui/system";
import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

const ReportPage = () => {
  const today = new Date();
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(today.getDate() - 6);

  const [startDate, setStartDate] = useState(oneWeekAgo);
  const [endDate, setEndDate] = useState(today);
  const [data, setData] = useState([]);
  const isMoreThanOneRow = data.length > 1;

  const StyledTable = styled(Table)`
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: "DM Sans", sans-serif;
    max-height: 800px;
    width: 100%;
    overflow-x: auto;

    .bold-row {
    font-weight: bold;
  }
  `;

  const columns = [
    {
      title: "Time Period",
      dataIndex: "timePeriod",
      key: "timePeriod",
      width: 300,
      fixed: "left",
    },
    {
      title: (
        <Tooltip title="The volume of pages received this current week.">
          Total Pages Received
        </Tooltip>
      ),
      dataIndex: "totalPagesReceived",
      key: "totalPagesReceived",
      width: 150,
    },
    {
      title: (
        <Tooltip title="The volume of pages downloaded this current week.">
          Total Pages Downloaded
        </Tooltip>
      ),
      dataIndex: "totalPagesDownloaded",
      key: "totalPagesDownloaded",
      width: 150,
    },
    {
      title: (
        <Tooltip title="Total number of pages completed and transmitted this current week.">
          Total Pages Completed
        </Tooltip>
      ),
      dataIndex: "totalPagesCompleted",
      key: "totalPagesCompleted",
      width: 150,
    },
    {
      title: (
        <Tooltip title="Total number of evaluations scheduled this current week, including those that are rescheduled.">
          Total Evaluations Scheduled
        </Tooltip>
      ),
      dataIndex: "totalEvaluationsScheduled",
      key: "totalEvaluationsScheduled",
      width: 150,
    },
    {
      title: (
        <Tooltip title="The total number of cases transmitted this week before their scheduled evaluation date.">
          Total Cases Completed Before Appointment
        </Tooltip>
      ),
      dataIndex: "totalCasesCompletedBeforeAppt",
      key: "totalCasesCompletedBeforeAppt",
      width: 150,
    },
    {
      title: (
        <Tooltip title="The total number of individual ICEs transmitted this week before the scheduled evaluation date. ">
          Total ICEs Completed Before Appointment
        </Tooltip>
      ),
      dataIndex: "totalICEsCompletedBeforeAppt",
      key: "totalICEsCompletedBeforeAppt",
      width: 150,
    },
    {
      title: (
        <Tooltip title="Percentage of cases transmitted this week before their scheduled evaluation date. The goal is to have more than 85% completed before the DOE.">
          Percentage Completed Before Appointment
        </Tooltip>
      ),
      dataIndex: "percentageCompletedBeforeAppt",
      key: "percentageCompletedBeforeAppt",
      width: 150,
    },
    {
      title: (
        <Tooltip title="The total number of cases transmitted after their scheduled evaluation date.">
          Total Cases Completed After Appointment
        </Tooltip>
      ),
      dataIndex: "totalCasesCompletedAfterAppt",
      key: "totalCasesCompletedAfterAppt",
      width: 150,
    },
    {
      title: (
        <Tooltip title="The total number of individual ICEs completed this week after the scheduled evaluation date.">
          Total ICEs Completed After Appointment
        </Tooltip>
      ),
      dataIndex: "totalICEsCompletedAfterAppt",
      key: "totalICEsCompletedAfterAppt",
      width: 150,
    },
    {
      title: (
        <Tooltip title="Percentage of cases transmitted this week after their scheduled evaluation date. The goal is to have less than 10% completed after the DOE.">
          Percentage Completed After Appointment
        </Tooltip>
      ),
      dataIndex: "percentageCompletedAfterAppt",
      key: "percentageCompletedAfterAppt",
      width: 150,
    },
    {
      title: (
        <Tooltip title="The total number of files received on the day or 24 hours before the evaluation.">
          Files Within 24 Hours
        </Tooltip>
      ),
      dataIndex: "filesWithin24Hours",
      key: "filesWithin24Hours",
      width: 150,
    },
    {
      title: (
        <Tooltip title="The percentage of ICEs completed and transmitted within the SLAs. The goal is to have 90% of cases in compliance with the SLAs.">
          Percentage ICEs Before Appointment
        </Tooltip>
      ),
      dataIndex: "percentageICEsBeforeAppt",
      key: "percentageICEsBeforeAppt",
      width: 150,
    },
    {
      title: (
        <Tooltip title="The average time it takes to complete and transmit the ICEs. The vendor should have a personal system keeping track of the start and end dates for each ICE and get the average. This will depict the number of cases that comply with the SLAs.">
          Average TAT
        </Tooltip>
      ),
      dataIndex: "averageTAT",
      key: "averageTAT",
      width: 150,
    },
    {
      title: (
        <Tooltip title="Files with over 2,000 pages of records.">
          Files With Pages Over 2000
        </Tooltip>
      ),
      dataIndex: "filesWithPagesOver2000",
      key: "filesWithPagesOver2000",
      width: 150,
    },
  ];

  const exportToCSV = () => {
    const csvRows = [];

    const headers = columns.map((col) => {
      if (React.isValidElement(col.title)) {
        return col.title.props.children;
      }
      return col.title;
    });
    csvRows.push(headers.join(","));

    data.forEach((row) => {
      const values = columns.map((col) => row[col.dataIndex]);
      csvRows.push(values.join(","));
    });

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `Report_${format(startDate, "yyyy-MM-dd")}_TO_${format(
        endDate,
        "yyyy-MM-dd"
      )}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (startDate && endDate) {
      fetchData(startDate, endDate);
    }
  }, [startDate, endDate]);

  const fetchData = async (startDate, endDate) => {
    const start = format(startDate, "yyyy-MM-dd");
    const end = format(endDate, "yyyy-MM-dd");
    

    try {
      const response = await downloadTrackerService.getReports(start, end);
      const data = await response.data;

      const processedData = data.map((item) => ({
        timePeriod: `${format(
          new Date(item.startDate),
          "do MMMM yyyy"
        )} to ${format(
          new Date(item.endDate),
          "do MMMM yyyy"
        )}`,
        totalPagesReceived: item.totalPagesReceived || 0,
        totalPagesDownloaded: item.totalPagesDownloaded || 0,
        totalPagesCompleted: item.totalPagesCompleted || 0,
        totalEvaluationsScheduled: item.totalEvaluationsScheduled || 0,
        totalCasesCompletedBeforeAppt: item.totalCasesCompletedBeforeAppt || 0,
        totalICEsCompletedBeforeAppt: item.totalICEsCompletedBeforeAppt || 0,
        percentageCompletedBeforeAppt:
          item.percentageCompletedBeforeAppt.toFixed(2) || 0,
        totalCasesCompletedAfterAppt: item.totalCasesCompletedAfterAppt || 0,
        totalICEsCompletedAfterAppt: item.totalICEsCompletedAfterAppt || 0,
        percentageCompletedAfterAppt:
          item.percentageCompletedAfterAppt.toFixed(2) || 0,
        filesWithin24Hours: item.filesWithin24Hours || 0,
        percentageICEsBeforeAppt: item.percentageICEsBeforeAppt.toFixed(2) || 0,
        averageTAT: item.averageTAT || 0,
        filesWithPagesOver2000: item.filesWithPagesOver2000 || 0,
      }));

      setData(processedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box
          style={{
            padding: "20px 0 20px 0",
            width: "95%",
            marginLeft: "2.5%",
          }}
        >
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item>
              <Typography
                variant="h4"
                gutterBottom
                fontWeight="700"
                lineHeight={"31.25px"}
                fontSize={"24px"}
              >
                CME Reports
              </Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing={3}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={exportToCSV}
                  >
                    Export to CSV
                  </Button>
                </Grid>
                <Grid item>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>

      <Box style={{ padding: "20px 0", width: "95%", marginLeft: "2.5%" }}>
        <StyledTable
          columns={columns}
          dataSource={data.map((item, index) => ({
            ...item,
            isLastRow: isMoreThanOneRow && index === data.length - 1 
          }))}
          pagination={false}
          sticky={true}
          scroll={{ x: "100%" }}
          rowClassName={(record) => (record.isLastRow ? 'bold-row' : '')}
        />
      </Box>
    </>
  );
};

export default ReportPage;
